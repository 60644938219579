define('ember-cli-moment-transform/transforms/moment-utc', ['exports', 'moment', 'ember-cli-moment-transform/transforms/moment'], function (exports, _moment, _emberCliMomentTransformTransformsMoment) {
  exports['default'] = _emberCliMomentTransformTransformsMoment['default'].extend({
    deserialize: function deserialize(serialized) {
      var result = this._super(serialized);

      if (_moment['default'].isMoment(result)) {
        return result.utc();
      }
    }
  });
});