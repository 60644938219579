define('ember-metismenu/components/ember-metismenu', ['exports', 'ember', 'ember-metismenu/templates/components/ember-metismenu'], function (exports, _ember, _emberMetismenuTemplatesComponentsEmberMetismenu) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberMetismenuTemplatesComponentsEmberMetismenu['default'],
    tagName: 'ul',
    classNames: 'metismenu',

    didInsertElement: function didInsertElement() {
      this.$().metisMenu();
    }
  });
});