define('ember-changeset-conditional-validations/validators/sometimes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateSometimes;
  function validateSometimes(validator, condition) {
    if (Array.isArray(arguments[0])) {
      var validators = arguments[0];
      return validators.map(guardValidatorWithCondition);
    } else {
      var _validator = arguments[0];
      return guardValidatorWithCondition(_validator);
    }

    function guardValidatorWithCondition(validator) {
      return function (key, newValue, oldValue, changes, content) {
        var thisValue = {
          get: function get(property) {
            if (property.includes('.')) {
              var changesValue = Ember.get(changes, property);
              if (typeof changesValue !== 'undefined') {
                return changesValue;
              }

              // Check if the `changes` value is explicitly undefined,
              // or if it's not present at all.
              var pathSegments = property.split('.');
              var propName = pathSegments.pop();
              var objPath = pathSegments.join('.');

              var obj = Ember.get(changes, objPath);
              if (obj && obj.hasOwnProperty && obj.hasOwnProperty(propName)) {
                return changesValue;
              }

              return Ember.get(content, property);
            }

            if (changes.hasOwnProperty(property)) {
              return Ember.get(changes, property);
            } else {
              return Ember.get(content, property);
            }
          }
        };

        if (condition.call(thisValue, changes, content)) {
          return validator(key, newValue, oldValue, changes, content);
        }
        return true;
      };
    }
  }
});