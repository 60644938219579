define('ember-cli-moment-transform/transforms/moment', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        var result = (0, _moment['default'])(serialized, _moment['default'].ISO_8601);
        if (_moment['default'].isMoment(result) && result.isValid()) {
          return result;
        }

        return null;
      } else {
        return serialized;
      }
    },

    serialize: function serialize(deserialized) {
      if (_moment['default'].isMoment(deserialized)) {
        return deserialized.clone().toISOString();
      } else {
        return null;
      }
    }
  });
});